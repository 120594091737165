import React, { useEffect, useState } from "react"
import Header from "../Global/Header"
import PageContent from "../Global/PageContent"
import Grid from "../Global/Grid"
import Popup from "./Popup"
import PopupWrapper from "../Global/Popup"
import PopupWhats from "./PopupWhats"
import api from "../../services/api"
import headphones from "../../assets/headphones.svg"
import chat from "../../assets/chat.svg"
import "./style.css"
import axios from "axios"
import updateLead from "../../inc/Fx/updateLead"

// const CONSULTAS = 0
// const EXAMEIMG = 2
// const COLETADOMICILIAR = 3

export default function Home() {
  const [popup, togglePopup] = useState(false)
  const [popupWhats, toggleWhats] = useState(false)

  useEffect(() => {
    //captura de parametros da URL
    sessionStorage.clear()
    const queryParams = new URLSearchParams(window.location.search)
    const source = queryParams.get('utm_source');
    const medium = queryParams.get('utm_medium');
    const content = queryParams.get('utm_content');
    const campaign = queryParams.get('utm_campaign');
    const term = queryParams.get('utm_term');

    if (source) sessionStorage.setItem("utm_source", source);
    if (medium) sessionStorage.setItem("utm_medium", medium);
    if (content) sessionStorage.setItem("utm_content", content);
    if (campaign) sessionStorage.setItem("utm_campaign", campaign);
    if (term) sessionStorage.setItem("utm_term", term);
    
    //retorno de handle de Alertas
    if (localStorage.getItem("showPopupOnReturn") === "true") {
      togglePopup(true)
      localStorage.removeItem("showPopupOnReturn")
    }

    async function initializeLead() {
      await api
      .post("/lead")
      .then((response) => {
        const { success, content } = response.data
        if (success) {
          sessionStorage.setItem("client", content)
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
        }
      })
    }
    if (!sessionStorage.getItem("client")) {
      initializeLead()
    }
  }, [])

  const handleConsultas = () => {
    updateLead({
      UTM_SOURCE: sessionStorage.getItem("utm_source"),
      UTM_MEDIUM: sessionStorage.getItem('utm_medium'),
      UTM_CONTENT: sessionStorage.getItem('utm_content'),
      UTM_CAMPAIGN: sessionStorage.getItem('utm_campaign'),
      UTM_TERM: sessionStorage.getItem('utm_term')
    })
    togglePopup(true)
  }
  const handleColetaDomiciliar = () => {
    window.location.replace("https://coleta.segmedic.com.br")
  }
  const handleExamesImg = () => {
    window.location.replace("https://exames.segmedic.com.br")
  }

  return (
    <main className="site-main homepage">
      <Header hideFollowUP={true} />
      <PageContent>
        <Popup isOpen={popup} toggle={togglePopup}></Popup>

        <PopupWrapper ID="popupWhats" open={popupWhats} handle={toggleWhats}>
          <PopupWhats />
        </PopupWrapper>

        <div className="title_home">
          <div>
            <h2>
              Bem-vindo ao <span>Agendamento Online</span>, a plataforma de
              <br /> Agendamento da Segmedic
            </h2>
            <p>
              Para começar o processo, escolha o <br /> que você deseja agendar
              hoje.
            </p>
          </div>
        </div>
        <form className="grid_home-initial">
          <Grid corDeFundo="#fff">
            <div ID="CONSULTAS" className="item" onClick={handleConsultas}>
              <div className="input-radio">
                <label htmlFor="input-consultas-medicas">
                  <input
                    type="radio"
                    name="select-especialidades"
                    id="input-consultas-medicas"
                    value="consultas-medicas"
                    // checked={choice === CONSULTAS}
                  />
                  <div className="input-label">
                    <div className="icon-initial">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                      >
                        <path d="M540-80q-108 0-184-76t-76-184v-23q-86-14-143-80.5T80-600v-240h120v-40h80v160h-80v-40h-40v160q0 66 47 113t113 47q66 0 113-47t47-113v-160h-40v40h-80v-160h80v40h120v240q0 90-57 156.5T360-363v23q0 75 52.5 127.5T540-160q75 0 127.5-52.5T720-340v-67q-35-12-57.5-43T640-520q0-50 35-85t85-35q50 0 85 35t35 85q0 39-22.5 70T800-407v67q0 108-76 184T540-80Zm220-400q17 0 28.5-11.5T800-520q0-17-11.5-28.5T760-560q-17 0-28.5 11.5T720-520q0 17 11.5 28.5T760-480Zm0-40Z" />
                      </svg>
                    </div>
                    <p>Agende agora</p>
                    <p>
                      <strong>
                        Consultas <br /> Médicas
                      </strong>
                    </p>
                  </div>
                </label>
              </div>
            </div>
            <div
              ID="COLETADOMICILIAR"
              className="item"
              onClick={handleColetaDomiciliar}
            >
              <div className="input-radio">
                <label htmlFor="input-coleta-domiciliar">
                  <input
                    type="radio"
                    name="select-especialidades"
                    id="input-coleta-domiciliar"
                    value="coleta-domiciliar"
                    disabled={false}
                    // checked={choice === COLETADOMICILIAR}
                  />
                  <div className="input-label">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                    >
                      <path d="M240-200v40q0 17-11.5 28.5T200-120h-40q-17 0-28.5-11.5T120-160v-320l84-240q6-18 21.5-29t34.5-11h440q19 0 34.5 11t21.5 29l84 240v320q0 17-11.5 28.5T800-120h-40q-17 0-28.5-11.5T720-160v-40H240Zm-8-360h496l-42-120H274l-42 120Zm-32 80v200-200Zm100 160q25 0 42.5-17.5T360-380q0-25-17.5-42.5T300-440q-25 0-42.5 17.5T240-380q0 25 17.5 42.5T300-320Zm360 0q25 0 42.5-17.5T720-380q0-25-17.5-42.5T660-440q-25 0-42.5 17.5T600-380q0 25 17.5 42.5T660-320Zm-460 40h560v-200H200v200Z" />
                    </svg>
                    <p>Agende agora</p>
                    <p>
                      <strong>
                        Atendimento <br /> Domiciliar
                      </strong>
                    </p>
                  </div>
                </label>
              </div>
            </div>
            <div ID="EXAMEIMG" className="item" onClick={handleExamesImg}>
              <div className="input-radio">
                <label htmlFor="input-exames-de-imagem">
                  <input
                    type="radio"
                    name="select-especialidades"
                    id="input-exames-de-imagem"
                    value="exames-de-imagem"
                    disabled={false}
                    // checked={choice === EXAMEIMG}
                  />
                  <div className="input-label">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                    >
                      <path d="M80-600v-120q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v120h-80v-120H160v120H80Zm80 440q-33 0-56.5-23.5T80-240v-120h80v120h640v-120h80v120q0 33-23.5 56.5T800-160H160Zm240-120q11 0 21-5.5t15-16.5l124-248 44 88q5 11 15 16.5t21 5.5h240v-80H665l-69-138q-5-11-15-15.5t-21-4.5q-11 0-21 4.5T524-658L400-410l-44-88q-5-11-15-16.5t-21-5.5H80v80h215l69 138q5 11 15 16.5t21 5.5Zm80-200Z" />
                    </svg>
                    <p>Agende agora</p>
                    <p>
                      <strong>
                        Exames e<br /> Procedimentos
                      </strong>
                    </p>
                  </div>
                </label>
              </div>
            </div>
          </Grid>
        </form>

        <div className="help_home_initial">
          <h2>
            Tem alguma dúvida,
            <br /> solicitação ou insatisfação?
          </h2>
          <p>
            Não hesite em nos contatar,
            <br /> estamos à disposição para te ajudar
            <br /> no que você precisar.
          </p>

          <div className="footer-initial">
            <div href="tel:+552126665800">
              <img src={headphones} />
              <h3>(21) 2666-5800</h3>
            </div>
            <div href="https://api.whatsapp.com/send?phone=2126665800">
              <img src={chat} />
              <h3>Preencha nosso formulário</h3>
            </div>
            <div className="home_footer_end"></div>
          </div>
        </div>
      </PageContent>
    </main>
  )
}
